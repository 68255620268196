import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { actualItineraryPathSegmentReducer } from '../itinerary/state/actualItineraryPathSegmentReducer'
import { aroundMeModeReducer } from '../global/state/aroundMeModeReducer'
import { clickOnSearchBarReducer } from '../navBar/state/clickOnSearchBarReducer'
import { closestLvzInfoReducer } from '../detailsPanel/state/closestLvzInfoReducer'
import { closestLvzPathsReducer } from '../detailsPanel/state/closestLvzPathsReducer'
import { closestParkingsInfoReducer } from '../detailsPanel/state/closestParkingsInfoReducer'
import { cocoLoaderStateReducer } from '../userIntroduction/state/cocoLoaderStateReducer'
import { cocoSearchMarkerReducer } from '../markers/state/cocoSearchMarkerReducer'
import { deferredPromptReducer } from '../installation/state/deferredPromptReducer'
import { detailsPanelExpandedReducer } from '../detailsPanel/state/detailsPanelExpandedReducer'
import { directionToDestinationReducer } from '../markers/state/directionToDestinationReducer'
import { displayAddAddressPopupReducer } from '../popup/state/displayAddAddressPopupReducer'
import { displayAskGetLocationReducer } from '../cocoparksIntroduction/state/displayAskGetLocationReducer'
import { displayCityInformationsDetailsPanelReducer } from '../detailsPanel/state/displayCityInformationsDetailsPanelReducer'
import { displayConfirmationLeavingItineraryReducer } from '../itinerary/state/displayConfirmationLeavingItineraryReducer'
import { displayConfirmationLeavingSpotReducer } from '../sparkedMode/state/displayConfirmationLeavingSpotReducer'
import { displayDestinationNotReachYetReducer } from '../itinerary/state/displayDestinationNotReachYetReducer'
import { displayDetailsPanelContainerReducer } from '../detailsPanel/state/displayDetailsPanelContainerReducer'
import { displayFavoriteAndRecentAddressesReducer } from '../navBar/state/displayFavoriteAndRecentAddressesReducer'
import { displayGetBackToRecentTorchAddressReducer } from '../itinerary/state/displayGetBackToRecentTorchAddressReducer'
import { displayGoingToCloseParkingReducer } from '../itinerary/state/displayGoingToCloseParkingReducer'
import { displayInstallationLegendIphoneReducer } from '../installation/state/displayInstallationLegendIphoneReducer'
import { displayInstallCocoparksReducer } from '../installation/state/displayInstallCocoparksReducer'
import { displayInteractiveBoxReducer } from '../cocosupporter/state/displayInteractiveBoxReducer'
import { displayItineraryReducer } from '../itinerary/state/displayItineraryReducer'
import { displayItineraryOverviewReducer } from '../itinerary/state/displayItineraryOverviewReducer'
import { displayLvzSpecificDetailPanelReducer } from '../detailsPanel/state/displayLvzSpecificDetailPanelReducer'
import { displayModificationContainerReducer } from '../userMenu/state/displayModificationContainerReducer'
import { displayMustLoginScreenReducer } from '../userMenu/state/displayMustLoginScreenReducer'
import { displayParkingLoaderReducer } from '../loaders/state/displayParkingLoaderReducer'
import { displayParkingResearchFromIntroReducer } from '../userIntroduction/state/displayParkingResearchFromIntroReducer'
import { displayParkingSpecificDetailPanelReducer } from '../detailsPanel/state/displayParkingSpecificDetailPanelReducer'
import { displaySparkedOnStreetSuccessReducer } from '../itinerary/state/displaySparkedOnStreetSuccessReducer'
import { displayTimeToParkContainerReducer } from '../sparkedMode/state/displayTimeToParkContainerReducer'
import { displayTorchErrorPopupReducer } from '../popup/state/displayTorchErrorPopupReducer'
import { displayTorchNotAvailableScreenReducer } from '../itinerary/state/displayTorchNotAvailableScreenReducer'
import { displayUserMenuReducer } from '../userMenu/state/displayUserMenuReducer'
import { displayUserResearchChoiceReducer } from '../userIntroduction/state/displayUserResearchChoiceReducer'
import { displayZoneWithRegulationsButtonReducer } from '../liveviewZone/state/displayZoneWithRegulationsButtonReducer'
import { evhInfoWindowReducer } from '../markers/state/evhInfoWindowReducer'
import { evhMarkerDataReducer } from '../markers/state/evhMarkerDataReducer'
import { filterModeActiveReducer } from '../filters/state/filterModeActiveReducer'
import { firstClosestParkingInfoReducer } from '../detailsPanel/state/firstClosestParkingInfoReducer'
import { freedSpotMarkerDataReducer } from '../markers/state/freedSpotMarkerDataReducer'
import { frontStyleEvhReducer } from '../markers/state/frontStyleEvhReducer'
import { frontStyleLvzReducer } from '../markers/state/frontStyleLvzReducer'
import { frontStyleOnstreetCommonReducer } from '../markers/state/frontStyleOnstreetCommonReducer'
import { frontStyleOnstreetDeliveryReducer } from '../markers/state/frontStyleOnstreetDeliveryReducer'
import { frontStyleParkingReducer } from '../markers/state/frontStyleParkingReducer'
import { frontStylePmrReducer } from '../markers/state/frontStylePmrReducer'
import { frontStyleSparkedCocoReducer } from '../markers/state/frontStyleSparkedCocoReducer'
import { itineraryModeReducer } from '../global/state/itineraryModeReducer'
import { itineraryPathReducer } from '../itinerary/state/itineraryPathReducer'
import { labelPositionReducer } from '../markers/state/labelPositionReducer'
import { lastTorchGeolocationReducer } from '../itinerary/state/lastTorchGeolocationReducer'
import { liveIndicReducer } from '../detailsPanel/state/liveIndicReducer'
import { liveParkingAvailabilitiesReducer } from '../global/state/liveParkingAvailabilitiesReducer'
import { lvzInfoReducer } from '../detailsPanel/state/lvzInfoReducer'
import { lvzInfoWindowReducer } from '../markers/state/lvzInfoWindowReducer'
import { lvzMarkerDataReducer } from '../markers/state/lvzMarkerDataReducer'
import { lvzMarkerRealTimeReducer } from '../markers/state/lvzMarkerRealTimeReducer'
import { mapCenterReducer } from '../map/state/mapCenterReducer'
import { mapFilterTrackersReducer } from '../filters/state/mapFilterTrackersReducer'
import { navBarReducer } from '../navBar/state/navBarReducer'
import { nbOfSlotsPassedReducer } from '../itinerary/state/nbOfSlotsPassedReducer'
import { onstreetCommonMarkerDataReducer } from '../markers/state/onstreetCommonMarkerDataReducer'
import { onstreetDeliveryInfoWindowReducer } from '../markers/state/onstreetDeliveryInfoWindowReducer'
import { onstreetDeliveryMarkerDataReducer } from '../markers/state/onstreetDeliveryMarkerDataReducer'
import { parkingMarkerDataReducer } from '../markers/state/parkingMarkerDataReducer'
import { parkingMarkerIdReducer } from '../markers/state/parkingMarkerIdReducer'
import { parkingTagTableReducer } from '../sparkedMode/state/parkingTagTableReducer'
import { pmrMarkerDataReducer } from '../markers/state/pmrMarkerDataReducer'
import { researchModeReducer } from '../global/state/researchModeReducer'
import { screenshotImageSpotReducer } from '../sparkedMode/state/screenshotImageSpotReducer'
import { selectedParkingMarkerReducer } from '../markers/state/selectedParkingMarkerReducer'
import { showNavMenuReducer } from '../navBar/state/showNavMenuReducer'
import { showParkingInProgressPopUpReducer } from '../popup/state/showParkingInProgressPopUpReducer'
import { showPriceAreaReducer } from '../map/state/showPriceAreaReducer'
import { showTrafficReducer } from '../map/state/showTrafficReducer'
import { sparkedAddressReducer } from '../sparkedMode/state/sparkedAddressReducer'
import { sparkedCocoMarkersDataReducer } from '../socketio/state/sparkedCocoMarkersDataReducer'
import { sparkedMarkerReducer } from '../markers/state/sparkedMarkerReducer'
import { sparkedModeReducer } from '../global/state/sparkedModeReducer'
import { sparkedParkingDataReducer } from '../sparkedMode/state/sparkedParkingDataReducer'
import { specificParkingDataReducer } from '../detailsPanel/state/specificParkingDataReducer'
import { specificLvzDataReducer } from '../detailsPanel/state/specificLvzDataReducer'
import { startOfLastItinerarySearchReducer } from '../itinerary/state/startOfLastItinerarySearchReducer'
import { streetParkingCostAllTimeReducer } from '../detailsPanel/state/streetParkingCostAllTimeReducer'
import { streetParkingInfoReducer } from '../detailsPanel/state/streetParkingInfoReducer'
import { torchButtonReducer } from '../torchButton/state/torchButtonReducer'
import { torchJsonReducer } from '../itinerary/state/torchJsonReducer'
import { trackingIdAndAnonymousLocalReducer } from '../userTracking/state/trackingIdAndAnonymousLocalReducer'
import { trackingSessionIdReducer } from '../userTracking/state/trackingSessionIdReducer'
import { ttpInfoReducer } from '../sparkedMode/state/ttpInfoReducer'
import { userInfoJsonReducer } from '../userMenu/state/userInfoJsonReducer'
import { userLocationReducer } from '../markers/state/userLocationReducer'
import { userUidReducer } from '../global/state/userUidReducer'
import { walkingCircleReducer } from '../markers/state/walkingCircleReducer'


export default createStore(
    combineReducers({
        actualItineraryPathSegment: actualItineraryPathSegmentReducer,
        aroundMeMode: aroundMeModeReducer,
        clickOnSearchBar: clickOnSearchBarReducer,
        closestLvzInfo: closestLvzInfoReducer,
        closestLvzPaths: closestLvzPathsReducer,
        closestParkingsInfo: closestParkingsInfoReducer,
        cocoLoaderState: cocoLoaderStateReducer,
        cocoSearchMarker: cocoSearchMarkerReducer,
        deferredPrompt: deferredPromptReducer,
        detailsPanelExpanded: detailsPanelExpandedReducer,
        directionToDestination: directionToDestinationReducer,
        displayAddAddressPopup: displayAddAddressPopupReducer,
        displayAskGetLocation: displayAskGetLocationReducer,
        displayCityInformationsDetailsPanel: displayCityInformationsDetailsPanelReducer,
        displayConfirmationLeavingItinerary: displayConfirmationLeavingItineraryReducer,
        displayConfirmationLeavingSpot: displayConfirmationLeavingSpotReducer,
        displayDestinationNotReachYet: displayDestinationNotReachYetReducer, 
        displayDetailsPanelContainer: displayDetailsPanelContainerReducer,
        displayFavoriteAndRecentAddresses: displayFavoriteAndRecentAddressesReducer,
        displayGetBackToRecentTorchAddress: displayGetBackToRecentTorchAddressReducer,
        displayGoingToCloseParking: displayGoingToCloseParkingReducer,
        displayInstallationLegendIphone: displayInstallationLegendIphoneReducer,
        displayInstallCocoparks: displayInstallCocoparksReducer,
        displayInteractiveBox: displayInteractiveBoxReducer,
        displayItinerary: displayItineraryReducer,
        displayItineraryOverview: displayItineraryOverviewReducer,
        displayLvzSpecificDetailPanel: displayLvzSpecificDetailPanelReducer,
        displayModificationContainer: displayModificationContainerReducer,
        displayMustLoginScreen: displayMustLoginScreenReducer,
        displayParkingLoader: displayParkingLoaderReducer,
        displayParkingResearchFromIntro: displayParkingResearchFromIntroReducer,
        displayParkingSpecificDetailPanel: displayParkingSpecificDetailPanelReducer,
        displaySparkedOnStreetSuccess: displaySparkedOnStreetSuccessReducer,
        displayTimeToParkContainer: displayTimeToParkContainerReducer,
        displayTorchErrorPopup: displayTorchErrorPopupReducer,
        displayTorchNotAvailableScreen: displayTorchNotAvailableScreenReducer,
        displayUserMenu: displayUserMenuReducer,
        displayUserResearchChoice: displayUserResearchChoiceReducer,
        displayZoneWithRegulationsButton: displayZoneWithRegulationsButtonReducer,
        evhInfoWindow: evhInfoWindowReducer,
        evhMarkerData: evhMarkerDataReducer,
        filterModeActive: filterModeActiveReducer,
        firstClosestParkingInfo: firstClosestParkingInfoReducer,
        freedSpotMarkerData: freedSpotMarkerDataReducer,
        frontStyleEvh: frontStyleEvhReducer,
        frontStyleLvz: frontStyleLvzReducer,
        frontStyleOnstreetCommon: frontStyleOnstreetCommonReducer,
        frontStyleOnstreetDelivery: frontStyleOnstreetDeliveryReducer,
        frontStyleParking: frontStyleParkingReducer,
        frontStylePmr: frontStylePmrReducer,
        frontStyleSparkedCoco: frontStyleSparkedCocoReducer,
        itineraryMode: itineraryModeReducer,
        itineraryPath: itineraryPathReducer,
        labelPosition: labelPositionReducer,
        lastTorchGeolocation: lastTorchGeolocationReducer,
        liveIndic: liveIndicReducer,
        liveParkingAvailabilities: liveParkingAvailabilitiesReducer,
        lvzInfo: lvzInfoReducer,
        lvzInfoWindow: lvzInfoWindowReducer,
        lvzMarkerData: lvzMarkerDataReducer,
        lvzMarkerRealTime: lvzMarkerRealTimeReducer,
        mapCenter: mapCenterReducer,
        mapFilterTrackers: mapFilterTrackersReducer,
        navBarState: navBarReducer,
        nbOfSlotsPassed: nbOfSlotsPassedReducer,
        onstreetCommonMarkerData: onstreetCommonMarkerDataReducer,
        onstreetDeliveryInfoWindow: onstreetDeliveryInfoWindowReducer,
        onstreetDeliveryMarkerData: onstreetDeliveryMarkerDataReducer,
        parkingMarkerData: parkingMarkerDataReducer,
        parkingMarkerId: parkingMarkerIdReducer,
        parkingTagTable: parkingTagTableReducer,
        pmrMarkerData: pmrMarkerDataReducer,
        researchMode: researchModeReducer,
        screenshotImageSpot: screenshotImageSpotReducer,
        selectedParkingMarker: selectedParkingMarkerReducer,
        showNavMenu: showNavMenuReducer,
        showParkingInProgressPopUp: showParkingInProgressPopUpReducer,
        showPriceArea: showPriceAreaReducer,
        showTraffic: showTrafficReducer,
        sparkedAddress: sparkedAddressReducer,
        sparkedCocoMarkersData: sparkedCocoMarkersDataReducer,
        sparkedMarker: sparkedMarkerReducer,
        sparkedMode: sparkedModeReducer,
        sparkedParkingData: sparkedParkingDataReducer,
        specificParkingData: specificParkingDataReducer,
        specificLvzData: specificLvzDataReducer,
        startOfLastItinerarySearch: startOfLastItinerarySearchReducer,
        streetParkingCostAllTime: streetParkingCostAllTimeReducer,
        streetParkingInfo: streetParkingInfoReducer,
        torchButton: torchButtonReducer,
        torchJson: torchJsonReducer,
        trackingIdAndAnonymousLocal: trackingIdAndAnonymousLocalReducer,
        trackingSessionId: trackingSessionIdReducer,
        ttpInfo: ttpInfoReducer,
        userInfoJson: userInfoJsonReducer, 
        userLocation: userLocationReducer,
        userUid: userUidReducer,
        walkingCircle: walkingCircleReducer,
    }),
    composeWithDevTools(
        applyMiddleware(thunk),
    )
)