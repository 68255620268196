import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { userLocationSelector } from '../markers/state/userLocationSelector'

//REDUX ACTIONS
import { toggleUserMenu } from './state/displayUserMenuAction ';

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './UserMenu.css'
import logo from '../images/logo.png'


function UserMenuIcon() {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const userLocation = useSelector(userLocationSelector)

    function toggleUserMenuOnRight(status){
        dispatch(toggleUserMenu(status))
    }

    return (
        <button 
            disabled={ userLocation ? false : true}
            style={{borderStyle : "none", background: "none", padding: "0px", margin: "0px", marginRight: "10px"}}
            onClick={()=> {toggleUserMenuOnRight(true); addActionToDbBasedOnElement("openLoginNav", trackingIdAndAnonymousLocal, trackingSessionId)}}
        >
            <img src={logo} alt="Logo" style={{width: "35px", height: "35px"}}/>
        </button>
    )
}

export default UserMenuIcon;