import React from "react";
import { useSelector } from 'react-redux'

//COMPONENTS
import InstallCocoparks from '../installation/InstallCocoparks';
import SearchBar from './SearchBar';
import UserMenuIcon from "../userMenu/UserMenuIcon";

//REDUX SELECTORS
import { displayInstallCocoparksSelector } from '../installation/state/displayInstallCocoparksSelector'

//STYLE
import './NavBar.css';
import '../global/Global.css'

function NavBar(props) {

    const displayInstallCocoparks = useSelector(displayInstallCocoparksSelector)
    
    return (
        <div className="flex-center-inline" style={{ position: "absolute", top: "0", left: "0", zIndex: "10", width: "100%", paddingTop: "1em"}}>
            <div className="navbar-div flex-space-between-inline">
                
                <SearchBar
                    map={props.map}
                    geolocationActive={props.geolocationActive}
                />
                
                {displayInstallCocoparks ?
                    <InstallCocoparks/>
                    :null}

                <UserMenuIcon/>
            </div>
        </div>
    )
    
}

export default NavBar
