import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from "react-transition-group";

//COMPONENTS
import ExpandedDetailsPanel from './ExpandedDetailsPanel';
import OriginalDetailsPanel from './OriginalDetailsPanel'
import BackToUserLocationButton from '../backToUserLocation/BackToUserLocationButton';
import TorchButton from '../torchButton/TorchButton';
import SparkedButton from '../sparkedMode/SparkedButton'

//REDUX SELECTOR
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";
import { detailsPanelExpandedSelector } from "./state/detailsPanelExpandedSelector";
import { displayDetailsPanelContainerSelector } from "./state/displayDetailsPanelContainerSelector";
import { parkingMarkerIdSelector } from '../markers/state/parkingMarkerIdSelector'
import { torchButtonSelector } from '../torchButton/state/torchButtonSelector';
import { itineraryModeSelector } from "../global/state/itineraryModeSelector";
import { parkingMarkerDataSelector } from '../markers/state/parkingMarkerDataSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { toggleExpandedDetailPanel } from './state/detailsPanelExpandedAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { hideCocoSearchMarker , showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { activateLiveIndicAnimation, deactivateLiveIndicAnimation } from './state/liveIndicAction'
import { storeFirstClosestParkingInfo } from './state/firstClosestParkingInfoAction'
import { storeStreetParkingInfo } from './state/streetParkingInfoAction'
import { resetPathToDestination } from '../markers/state/directionToDestinationAction'
import { resetClosestLvzPaths } from './state/closestLvzPathsAction'
import { updateParkingMarkerData } from '../markers/state/parkingMarkerDataAction'
import { updateMarkerId } from "../markers/state/parkingMarkerIdAction";
import { storeLiveParkingsAvailabilities } from '../global/state/liveParkingAvailabilitiesAction'
import { toggleInteractiveBox } from '../cocosupporter/state/displayInteractiveBoxAction'
import { resetClosestParkingsInfo } from './state/closestParkingsInfoAction';
import { resetClosestLvzInfo } from './state/closestLvzInfoAction'

//UTILS
import { getClosestParking, getLocationLiveInfo } from '../api/originalDetailsPanelApi';
import { calculateDistanceToClosetParking, getInsightsFromResearch } from '../global/MapUtils'
import { getParkingData, getParkingsSpotsAvailabilities} from '../api/mapApi';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
import * as global from '../global/globalVariable'


//STYLE
import './DetailsPanelContainer.css'
import * as IoIcon from 'react-icons/io5';



export const DetailsPanelContainer = React.memo((props) => {

    const dispatch = useDispatch()

    const nodeRefExpanded = useRef(null)
    const nodeRefOriginal = useRef(null)
    const nodeRefTorchButton = useRef(null)
    const nodeRefRightButton = useRef(null)

    const aroundMeMode = useSelector(aroundMeModeSelector)
    const detailsPanelExpanded = useSelector(detailsPanelExpandedSelector)
    const displayDetailsPanelContainer = useSelector(displayDetailsPanelContainerSelector)
    const parkingMarkerId = useSelector(parkingMarkerIdSelector)
    const torchButton = useSelector(torchButtonSelector)
    const itineraryMode = useSelector(itineraryModeSelector)
    const parkingMarkerData = useSelector(parkingMarkerDataSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const [displayTorchButton, setDisplayTorchButton] = useState(torchButton)
    const [displayRightButton, setDisplayRightButton] = useState(true)
    //to display detail panel placeholder
    const [placeholderWrapperCard, setPlaceholderWrapperCard] = useState(true)

    const fetchParkingData = async (center) => {
        await getParkingData(center)
        .then(async function(response){
            if(response !== undefined && response.results !== -1){
                //set markersIds with the id of all fetched parking
                await dispatch(updateParkingMarkerData(response.results.parkings))
                let array = parkingMarkerId
                response.results.parkings.forEach((parking)=>{
                    array.push(parking.index)
                })
                let uniqueArrayMarkersIds = array.filter(function(item, pos, self) {
                    return self.indexOf(item) === pos;
                })
                dispatch(updateMarkerId(uniqueArrayMarkersIds))
                await getParkingsSpotsAvailabilities(uniqueArrayMarkersIds)
                .then(function(response){
                    dispatch(storeLiveParkingsAvailabilities(response.results)) 
                })
            }
        })
    };

    //all functions called on swipe down on details panel
    function OnSwipeDownCalling(){
        dispatch(toggleExpandedDetailPanel(false))
        dispatch(toggleResearchMode({value: false}))
        dispatch(hideCocoSearchMarker())
        dispatch(resetPathToDestination())
        dispatch(resetClosestLvzPaths())
        dispatch(resetClosestParkingsInfo())
        dispatch(resetClosestLvzInfo())
    }


    //all functions called on swipe up on details panel
    async function OnSwipeUpCalling(){
        dispatch(toggleExpandedDetailPanel(true))
        let center = props.map.getCenter().toJSON()
        await fetchParkingData(center)
        dispatch(toggleResearchMode({value: true, location: props.map.getCenter().toJSON()}))
        //to display cocoSearchMarker if user isn't in aroundMeMode
        if(!aroundMeMode){
            dispatch(showCocoSearchMarker(center))
        }
        //to get the number of parking within600 to display or not interactive box and the appropriate message
        await getInsightsFromResearch(center, parkingMarkerData)
        .then(async function(researchInsights){
            dispatch(toggleInteractiveBox({value: true, category: "parking", nbWithin600: researchInsights.nbWithin600}))
        }); 
    }

    
    //to swipe up and down details panel
    const handlers = useSwipeable(
        { 
        onSwipedDown: 
            () => {
                OnSwipeDownCalling();
                addActionToDbBasedOnElement("swipeIndicatorDown", trackingIdAndAnonymousLocal, trackingSessionId)
            } ,
        onSwipedUp:
            () => {
                OnSwipeUpCalling();
                addActionToDbBasedOnElement("swipeIndicatorUp", trackingIdAndAnonymousLocal, trackingSessionId)
            }
        }
    )


    const styleChevron = {
        transform: detailsPanelExpanded ? 'rotate(180deg)' : '', 
        transition: 'transform 1000ms ease', // smooth transition
        color: 'black', 
        height: "30px", 
        width: "30px",
    } 

    
    const styleContainer = {
        position: "sticky",
        bottom: '0px',
        transform: !displayDetailsPanelContainer ? "translateY(150px)" : "",
        transition: !displayDetailsPanelContainer ? 'transform 1000ms ease' : 'transform 500ms ease',
        minHeight: "20vh",
        zIndex: "3"
    } 

    
    //to display on street data on detail panel
    //Update OriginalDetailsPanel with time and cost
    async function displayStreetLiveInfo(){
        let streetParkingCost = "-"
        let timeString = "-"
        const resp = await getLocationLiveInfo(props.mapCenter)
        if(resp !== undefined && resp.results !== -1){
            if(resp.results.time){
                //The back returns the time as an index. 
                //The timeIndexTo array allows this number to be translated into a string 
                timeString = global.timeIndexConversionToString[resp.results.time];
            }
            if(resp.message !== "No information avalaible in this city"){
                if(resp.results.price_info !== null && resp.results.price_info !== "-1"){
                    if(resp.results.price_info['is_free'] === false){
                        streetParkingCost = resp.results.price_info.prices['60'];
                    }else{
                        streetParkingCost = "0";
                    }
                }
            }
        }
        dispatch(storeStreetParkingInfo(timeString, streetParkingCost))
    }


    //to display data from the closest parking on detail panel
    async function displayClosestParkingInfo(){
        const resp = await getClosestParking(props.mapCenter)
        let parkingTime = "-"
        let parkingCost = "-"
        if(resp!== undefined && resp.results !== -1){
            parkingTime = calculateDistanceToClosetParking(props.mapCenter, resp.results.location)
            if(resp.results.price_1_h !== null){
                parkingCost = resp.results.price_1_h
            }
        }
        dispatch(storeFirstClosestParkingInfo(parkingTime, parkingCost))
    }


    //to animate liveindic on detail panel each time the map moved
    function animatedLiveIndic(){
        dispatch(activateLiveIndicAnimation())
        setTimeout(()=>{
            dispatch(deactivateLiveIndicAnimation())
        }, 3000)
    }


    //to update data of detail panel when map center changes
    useEffect(() => {
        const fetchData = async () => {
            if(props.mapCenter !== null){
                animatedLiveIndic()
                displayStreetLiveInfo()
                displayClosestParkingInfo()
            }
        };
        fetchData();
    }, [props.mapCenter])

    useEffect(() => {
        if(torchButton){
            if(!itineraryMode && !detailsPanelExpanded){
                setDisplayTorchButton(true)
            } else {
                setDisplayTorchButton(false)
            }
        }else {
            setDisplayTorchButton(false)
        }
    }, [torchButton, itineraryMode, detailsPanelExpanded])

    useEffect(() => {
        if(!itineraryMode && !detailsPanelExpanded){
            setDisplayRightButton(true)
        } else {
            setDisplayRightButton(false)
        }
    }, [itineraryMode, detailsPanelExpanded])


    return (
        <div style={styleContainer}>
            <div className="flex-space-between-inline" style={{width: "100vw", position: "relative"}} >
                <CSSTransition
                    in={displayTorchButton}
                    timeout={500}
                    classNames="torch-button"
                    unmountOnExit
                    nodeRef={nodeRefTorchButton}
                >
                    <div style={{position: "absolute", top: "-85px", left: "15px", width: "70px", height: "70px"}} ref={nodeRefTorchButton}>
                        <TorchButton/>
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={displayRightButton}
                    timeout={500}
                    classNames="right-button"
                    unmountOnExit
                    nodeRef={nodeRefRightButton}
                >
                    <div style={{position: "absolute", top: "-85px", right: "15px", width: "70px", height: "70px"}} ref={nodeRefRightButton}>
                        {aroundMeMode? <SparkedButton/> : <BackToUserLocationButton map={props.map}/>}
                    </div>
                </CSSTransition>
                
                <div className="details-panel flex-center-column"  {...handlers}>
                    <button 
                        disabled={placeholderWrapperCard && detailsPanelExpanded ? true : false}
                        style={{ borderStyle: "hidden", backgroundColor: "transparent"}}
                        onClick={()=> {
                            if(detailsPanelExpanded){
                                OnSwipeDownCalling()
                                addActionToDbBasedOnElement("swipeIndicatorDown", trackingIdAndAnonymousLocal, trackingSessionId)
                            } else {
                                OnSwipeUpCalling()
                                addActionToDbBasedOnElement("swipeIndicatorUp", trackingIdAndAnonymousLocal, trackingSessionId)
                            }

                        }}
                    >
                        <IoIcon.IoChevronUpOutline style={styleChevron} />
                    </button>
                    <CSSTransition
                        in={!detailsPanelExpanded}
                        timeout={250}
                        classNames="original-panel"
                        unmountOnExit
                        nodeRef={nodeRefOriginal}
                        >
                        <div className="container-original flex-center-column" ref={nodeRefOriginal}>
                            <OriginalDetailsPanel/> 
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        in={detailsPanelExpanded}
                        timeout={800}
                        classNames="expanded-panel"
                        unmountOnExit
                        nodeRef={nodeRefExpanded}
                    >
                        <div className="container-expanded" ref={nodeRefExpanded}>
                            <div className="flex-center-column">
                                <ExpandedDetailsPanel 
                                    map={props.map}
                                    fetchLvz={props.fetchLvz}
                                    placeholderWrapperCard={placeholderWrapperCard}
                                    setPlaceholderWrapperCard={setPlaceholderWrapperCard}
                                />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        </div>
    )
})

export default DetailsPanelContainer
