import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';

//COMPONENTS
import AskGetLocation from './AskGetLocation';

//REDUX SELECTORS
import { displayAskGetLocationSelector } from './state/displayAskGetLocationSelector'

//REDUX ACTIONS
import { toggleAskGetLocation } from './state/displayAskGetLocationAction';

//UTILS
import introData from './introductionTextContent.json'

//STYLE
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './IntroductionSwiper.css'

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

function IntroductionSwiper() {

    const dispatch = useDispatch()

    const  displayAskGetLocation = useSelector(displayAskGetLocationSelector)

    /**
     * adds a 1 in local storage to ensure Cocoparks recognizes user has already connected
     * if not found, then considers first connection and shows tutorial
     */
    function incrementLocalStorage(){
        localStorage.setItem('knownCoco', '1');
    }

    //to display component AskGetLocation
    function showAskGetLocation(){
        dispatch(toggleAskGetLocation(true))
    }

    if(displayAskGetLocation){
        return(
            <AskGetLocation/>
        )
    } else {
        return (
            <div style={{backgroundColor: "var(--light-green)", width: "100vw", height: "100vh"}}>
                <Swiper
                    pagination={{
                        "dynamicBullets": true,
                    }}
                    navigation={true}
                >
                    {introData.map((item,key)=>{
                        if(key === introData.length - 1){
                            return(
                                <SwiperSlide key={key}
                                    style={{height: "85vh"}}
                                >
                                    <div className="introTop">
                                        <h2 className="title-intro">{item.title}</h2>
                                        <p className="content-intro">{item.content}</p>
                                    </div>
                                    <div className="intro-img">
                                        <img src={item.imagePath} alt="phone"/>
                                    </div>
                                    <button className="green-action-button" style={{marginBottom: "25px"}}
                                        onClick={()=> {incrementLocalStorage(); showAskGetLocation()}
                                        }
                                    >
                                        <span className="green-action-button-text">C'est parti !</span>
                                    </button>
                                </SwiperSlide>
                            )
                        } else {
                            return(
                                <SwiperSlide key={key}
                                    style={{height: "85vh"}}
                                >
                                    <div className="introTop">
                                        <h2 className="title-intro">{item.title}</h2>
                                        <p className="content-intro">{item.content}</p>
                                    </div>
                                    <div className="intro-img">
                                        <img src={item.imagePath} alt="phone"/>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    })}
                </Swiper>
            </div>
        )
    }

}

export default IntroductionSwiper
